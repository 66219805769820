<template>
  <div>
    <div class="card">
      <el-button type='primary' icon='el-icon-plus' @click="handleAdd">新增支付渠道</el-button>
      <el-button type='primary' plain @click="handleSort">修改排序</el-button>
    </div>
    <div class='table'>
      <el-table :row-class-name="handleIndex" :data='tableData' stripe v-loading="loading" height="700">
        <template v-for='(item, index) in rowList'>
          <el-table-column
            align='center'
            :key='index'
            :prop="item.field_alias"
            :label='item.field_text'
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <template v-if="item.field_alias !== 'status'">
                {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
              </template>
              <template v-else>
                <el-switch
                  v-model='scope.row.status'
                  :active-value="10"
                  :inactive-value="20"
                  @change="handleSwitch(scope.row)"
                >
                </el-switch>
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column align='center' fixed='right' field_alias='operation' label='操作'>
          <template slot-scope='scope'>
            <el-button type='text' :disabled="scope.row.isEdit ? false : true" @click="handleEdit(scope.row)">编辑</el-button>
            <!-- <el-button type='text' :disabled="scope.row.isEdit ? false : true" @click="handleDele(scope.row)" style="color: #F56C6C;">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="新增支付渠道"
      :visible.sync="addVisible"
      :destroy-on-close="true"
      width="30%"
      :before-close="handleClose">
      <el-form :model="form" ref="ruleForm"  :rules="rules"  label-width="80px">
        <el-form-item label="支付渠道" required prop="name">
          <el-input  maxlength="15" v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改排序"
      :visible.sync="sortVisible"
      width="30%"
      :before-close="handleClose">
      <div style="height: 500px; overflow-y: auto;">
        <ux-grid
              border
              row-key
              ref="plxTable"
              :data="data">
          <ux-table-column
                  v-for="item in rowList"
                  :key="item.id"
                  :resizable="item.resizable"
                  :field="item.field_alias"
                  :title="item.field_text"
                  :sortable="item.sortable"
                  :width="item.t_width"
                  :fixed="item.fixed"
            >
            <template v-slot="scope" v-if="item.field_alias === 'status'">
              {{ scope.row.status == 10 ? '开启' : '关闭' }}
            </template>
          </ux-table-column>
      </ux-grid>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sortVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSortSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs/modular/sortable.core.esm.js";
import { getListReq, addCashMethodsReq, updateOrderReq } from '@/api/system/settleConfig.js';

export default {
  name: 'CashMethods',
  data() {
    return {
      data: [],
      rules: {
        name: [
          {
            required: true, message: '请输入支付渠道', trigger: 'blur',
          },
          {
            required: true, message: '请输入支付渠道', trigger: 'change',
          },
        ],
      },
      form: {
        name: '',
      },
      sortVisible: false,
      addVisible: false,
      loading: false,
      rowList: [
        {
          field_text: '序号',
          field_alias: 'index',
        },
        {
          field_text: '收银方式',
          field_alias: 'name',
        },
        {
          field_text: '创建人',
          field_alias: 'store_user_name',
        },
        {
          field_text: '创建时间',
          field_alias: 'create_time',
        },
        {
          field_text: '状态',
          field_alias: 'status',
        },
      ],
      tableData: [],
    };
  },
  watch: {
    sortVisible: {
      handler(val) {
        if (val) {
          this.columnDrop();
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  },
  created() {
    this.getList('loading');
  },
  methods: {
    handleAdd() {
      this.addVisible = true;
      this.form = {
        name: '',
      };
    },
    handleSortSubmit() {
      const order_data = [];
      this.data.forEach((item) => {
        order_data.push(item.pay_channel_id);
      });
      this.updateOrder(order_data);
    },
    updateOrder(order_data) {
      updateOrderReq({
        order_data,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('操作成功');
            this.handleClose();
            this.getList();
          }
        });
    },
    handleSort() {
      this.data = this.tableData.concat();
      this.sortVisible = true;
    },
    handleDele(row) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.form = { ...row };
        this.form.status = 30;
        this.addCashMethods();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },
    handleEdit(row) {
      this.form = { ...row };
      this.addVisible = true;
    },
    addCashMethods() {
      addCashMethodsReq({
        ...this.form,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('操作成功');
            this.handleClose();
            this.getList();
          }
        })
        .catch(() => {
          this.getList();
        });
    },
    handleIndex({row, rowIndex}) {
      row.index = rowIndex + 1;
    },
    getList(loading) {
      if (loading) {
        this.loading = true;
      }
      getListReq()
        .then((res) => {
          if (res.code === 1) {
            this.tableData = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    columnDrop () {
      this.$nextTick(() => {
          let plxTable = this.$refs.plxTable;
          if (!document.getElementsByClassName('elx-table--body')[0]) {
            return;
          }
          const tbodyDom = document.getElementsByClassName('elx-table--body')[0].getElementsByTagName("tbody")[0];
          // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
          this.sortable = Sortable.create(tbodyDom, {
              handle: ".elx-body--row",  // Drag handle selector within list items
              // 拖拽结束
              onEnd: ({ item, newIndex, oldIndex }) => {
                   let currRow = this.data.splice(oldIndex, 1)[0]
                    this.data.splice(newIndex, 0, currRow)
              }
          })
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addCashMethods();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClose() {
      this.form = {
        name: '',
      };
      this.addVisible = false;
      this.sortVisible = false;
    },
    handleSwitch(row) {
      this.form = { ...row };
      this.addCashMethods('');
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>
