import { post } from '@/utils/request';

const addReq = (params) => post({
  url: '/store/hierarchy.HierarchySetCtl/add',
  data: {
    ...params,
  },
});

const getCashSetReq = (params) => post({
  url: '/store/hierarchy.HierarchySetCtl/getCashSet',
  data: {
    ...params,
  },
});

// 支付方式列表
const getListReq = (params) => post({
  url: '/store/hierarchy.PayChannelCtl/list',
  data: {
    ...params,
  },
});

// 新增支付方式
const addCashMethodsReq = (params) => post({
  url: '/store/hierarchy.PayChannelCtl/action_do',
  data: {
    ...params,
  },
});

// 保存排序
const updateOrderReq = (params) => post({
  url: '/store/hierarchy.PayChannelCtl/updateOrder',
  data: {
    ...params,
  },
});
// 开关列表
const getSetterListReq = (params) => post({
  url: '/store/hierarchy.HierarchySetCtl/getOnoffList',
  data: {
    ...params,
  },
});
// 查询开关设置
const getSetResultReq = (params) => post({
  url: '/store/hierarchy.HierarchySetCtl/getSetResult',
  data: params
});
// 新增开关
const addSetterMethodsReq = (params) => post({
  url: '/store/hierarchy.HierarchySetCtl/add',
  data: {
    ...params,
  },
});
export {
  addReq,
  getCashSetReq,
  getListReq,
  getSetterListReq,
  addSetterMethodsReq,
  updateOrderReq,
  getSetResultReq,
};
